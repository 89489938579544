import React from 'react'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutBlog from '../../components/LayoutBlog'
import * as BlogPageStyles from '../../styles/BlogPage.css'
import { BlogArticleDataList, BlogArticleDatas, BlogCategoryDataList, BlogCategoryDatas } from '../../types/blog'
import BlogTopCarousel from '../../components/BlogTopCarousel'
import SEO from '../../components/SEO'
import BlogTrendTag from '../../components/BlogTrendTag'
import BlogArticlesList from '../../components/BlogArticlesList'
import { graphql } from 'gatsby'

type BlogPageProps = {
  data: { allNewtBlogCategory: BlogCategoryDataList; allNewtBlogArticle: BlogArticleDataList }
} & PageContextPropsType

export default function BlogPage({ data, pageContext }: BlogPageProps) {
  const { allNewtBlogCategory } = data
  const { allNewtBlogArticle } = data

  return (
    <LayoutBlog type="top" pageContext={pageContext}>
      <SEO
        title="サイト制作・運用・成果改善のコツと最新事例を発信 | KARTE Blocks Blog"
        description="KARTE Blocks Blogは、サイト制作・運用・成果改善のコツとKARTE Blocksの最新事例を発信しています。"
        path="/blog/"
      />
      <BlogTopCarousel />
      <BlogTrendTag />
      <div className={BlogPageStyles.Container}>
        {allNewtBlogCategory.edges.map((category: BlogCategoryDatas) =>
          allNewtBlogArticle.edges.filter((article: BlogArticleDatas) => article.node.category?._id === category.node._id).length ? (
            <BlogArticlesList key={`category_${category.node._id}`} category={category} articles={allNewtBlogArticle} />
          ) : null
        )}
      </div>
    </LayoutBlog>
  )
}

export const query = graphql`
  query ($_id: String) {
    allNewtBlogCategory(sort: { order: ASC, fields: _id }) {
      edges {
        node {
          _id
          name
          slug
          description
        }
      }
    }
    allNewtBlogArticle(filter: { category: { _id: { eq: $_id } } }) {
      edges {
        node {
          _id
          title
          slug
          thumbnail {
            src
          }
          meta {
            metaPublishDate
          }
          tags {
            _id
            name
            slug
          }
          category {
            _id
            slug
          }
        }
      }
    }
  }
`
