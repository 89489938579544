import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as BlogTrendTagStyles from '../styles/BlogTrendTag.css'
import { BlogTagDatas } from '../types/blog'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BlogTrendTag: React.VFC = () => {
  const { allNewtBlogTag } = useStaticQuery(graphql`
    query MyQuery {
      allNewtBlogTag(filter: { trend: { eq: true } }) {
        edges {
          node {
            trend
            slug
            name
          }
        }
      }
    }
  `)

  return (
    <section className={BlogTrendTagStyles.Section}>
      <h3 className={BlogTrendTagStyles.Title}>トレンドタグの記事を見る</h3>
      <ul className={BlogTrendTagStyles.List}>
        {allNewtBlogTag.edges.map(
          (tag: BlogTagDatas, index: number) =>
            tag.node.trend && (
              <li key={index}>
                <a href={'/blog/tag/' + tag.node.slug + `/`} className={BlogTrendTagStyles.Tag}>
                  <span className={BlogTrendTagStyles.Name}>{tag.node.name}</span>
                  <FontAwesomeIcon icon={faChevronRight} width={7} height={24} />
                </a>
              </li>
            )
        )}
      </ul>
      <form className={BlogTrendTagStyles.Search} action="/blog/search/" method="get">
        <input name="q" type="search" placeholder="サイト内検索" className={BlogTrendTagStyles.Search_Input} />
        <FontAwesomeIcon icon={faMagnifyingGlass} width={14} height={24} className={BlogTrendTagStyles.Search_Icon} />
      </form>
    </section>
  )
}

export default BlogTrendTag
