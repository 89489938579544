import React, { useState } from 'react'
import { faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as SiteWideStyles from '../styles/SiteWide.css'
import cn from 'classnames'
import BlogCard from './BlogCard'
import * as BlogPageStyle from '../styles/BlogPage.css'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { BlogArticleDataList, BlogCategoryDatas } from '../types/blog'

type BlogArticlesListProps = {
  category: BlogCategoryDatas
  articles: BlogArticleDataList
}
const BlogArticlesList: React.VFC<BlogArticlesListProps> = ({ category, articles }: BlogArticlesListProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [slideRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 'auto',
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <section className={BlogPageStyle.Section_Area}>
      <div className={BlogPageStyle.Title_Inner}>
        <div className={BlogPageStyle.Title_Area}>
          <h2 className={BlogPageStyle.Section_Title}>{category.node.name}</h2>
          <span className={BlogPageStyle.Section_Sub_Title}>{category.node.description}</span>
        </div>
        <div className={BlogPageStyle.Button_Area}>
          <a href={`/blog/category/${category.node.slug}`} className={BlogPageStyle.Show_All_Button}>
            すべて見る
            <FontAwesomeIcon icon={faArrowRight} width={13} height={14} />
          </a>
        </div>
      </div>
      <div className={BlogPageStyle.Blog_Card_List_Outer}>
        <ul ref={slideRef} className={cn(BlogPageStyle.Blog_Card_List, 'keen-slider')}>
          {articles.edges
            .filter((article) => article.node.category?._id === category.node._id)
            .slice(0, 6)
            .map((article) => (
              <BlogCard key={`slide ${article.node._id}`} article={article.node} />
            ))}
        </ul>
        <div className={cn(BlogPageStyle.Carousel_Area, SiteWideStyles.DisplayDesktopOnly)}>
          {loaded && instanceRef.current && (
            <>
              <CarouselButton left disabled={currentSlide === 0} onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} />
              <CarouselButton
                left={false}
                disabled={
                  instanceRef.current.track.details?.slides && currentSlide === instanceRef.current?.track.details.slides.length - 3
                }
                onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
              />
            </>
          )}
        </div>
      </div>
      <div className={cn(BlogPageStyle.Carousel_Area, SiteWideStyles.DisplayMobileOnly)}>
        {loaded && instanceRef.current && (
          <>
            <CarouselButton left disabled={currentSlide === 0} onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} />
            <CarouselButton
              left={false}
              disabled={instanceRef.current.track.details?.slides && currentSlide === instanceRef.current?.track.details.slides.length - 1}
              onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            />
          </>
        )}
      </div>
    </section>
  )
}

type Props = {
  disabled: boolean
  left?: boolean
  onClick: (e: any) => void
}
export const CarouselButton = ({ disabled, left, onClick }: Props) => {
  return (
    <button disabled={disabled} onClick={onClick} className={BlogPageStyle.Carousel_Button}>
      <FontAwesomeIcon icon={left ? faChevronLeft : faChevronRight} width={9} height={18} className={BlogPageStyle.Carousel_Icon} />
    </button>
  )
}

export default BlogArticlesList
