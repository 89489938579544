import React, { useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { DateTime } from 'luxon'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as SiteWideStyles from '../styles/SiteWide.css'
import * as BlogPageStyles from '../styles/BlogPage.css'
import { BlogArticleDatas } from '../types/blog'

const BlogTopCarousel: React.VFC = () => {
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo').toFormat('yyyy.MM.dd')
    return Date
  }, [])

  const blogTopCarouseData = useStaticQuery(graphql`
    query {
      allNewtBlogArticle(limit: 4, sort: { fields: meta___metaPublishDate, order: DESC }) {
        totalCount
        edges {
          node {
            _id
            title
            slug
            thumbnail {
              _id
              src
            }
            meta {
              metaPublishDate
            }
            tags {
              _id
              name
              slug
            }
          }
        }
      }
      rough: file(relativePath: { eq: "blog/rough_gray_texture.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      squishy: file(relativePath: { eq: "blog/squishy_texture.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const { allNewtBlogArticle } = blogTopCarouseData

  return (
    <section className={BlogPageStyles.HeroContainer}>
      <div className={BlogPageStyles.HeroFrame}>
        <GatsbyImage image={blogTopCarouseData.squishy.childImageSharp.gatsbyImageData} alt="" className={BlogPageStyles.HeroCover} />
      </div>
      <div className={BlogPageStyles.HeroInner}>
        <div className={BlogPageStyles.HeroHead}>
          <h1 className={BlogPageStyles.HeroTitle}>
            KARTE Blocks <span>ブログ</span>
          </h1>
          <p className={BlogPageStyles.HeroLead}>サイト制作・運用・成果改善のコツと最新事例</p>
        </div>
        <div className={BlogPageStyles.HeroControl}>
          <div className={BlogPageStyles.HeroList}>
            {allNewtBlogArticle.edges.map((post: BlogArticleDatas, index: number) => (
              <a key={index} href={`/blog/` + post.node.slug + `/`} className={BlogPageStyles.HeroArticle}>
                <img className={BlogPageStyles.HeroArticle_Image} src={post.node.thumbnail.src} />
                <div className={BlogPageStyles.HeroArticle_Content}>
                  <p className={BlogPageStyles.HeroArticle_Date}>{FormatDate(post.node.meta.metaPublishDate)}</p>
                  <p className={BlogPageStyles.HeroArticle_Title}>{post.node.title}</p>
                  <div className={BlogPageStyles.HeroArticle_Tags}>
                    {post.node.tags.map((tag: any, index: number) => (
                      <span key={index} className={BlogPageStyles.HeroArticle_Tag}>
                        #{tag.name}
                      </span>
                    ))}
                  </div>
                </div>
              </a>
            ))}
          </div>
          <button id="prev" className={BlogPageStyles.HeroControl_Prev}>
            <FontAwesomeIcon icon={faChevronLeft} width={13} height={37} />
          </button>
          <button id="next" className={BlogPageStyles.HeroControl_Next}>
            <FontAwesomeIcon icon={faChevronRight} width={13} height={37} />
          </button>
        </div>
        <div id="dots" className={BlogPageStyles.HeroDots}>
          <span className={BlogPageStyles.HeroDot} />
          <span className={`${BlogPageStyles.HeroDot} ${BlogPageStyles.HeroDot_Green}`} />
          <span className={BlogPageStyles.HeroDot} />
          <span className={BlogPageStyles.HeroDot} />
        </div>
      </div>
    </section>
  )
}

export default BlogTopCarousel
