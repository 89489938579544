import React, { useCallback } from 'react'
import * as BlogPageStyle from '../styles/BlogPage.css'
import cn from 'classnames'
import { BlogArticleData } from '../types/blog'
import { DateTime } from 'luxon'

type BlogCardProps = {
  article: Partial<BlogArticleData>
}

const BlogCard: React.VFC<BlogCardProps> = ({ article }: BlogCardProps) => {
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo')
    return Date
  }, [])

  return (
    <li className={cn(BlogPageStyle.Card, 'keen-slider__slide')}>
      <a href={`/blog/${article.slug}`} className={BlogPageStyle.Card_Inner}>
        <article>
          <div className={BlogPageStyle.Card_Image_Outer}>
            {article.thumbnail ? <img src={article.thumbnail.src} alt="" className={BlogPageStyle.Card_Image} /> : <p>{article.title}</p>}
          </div>
          <div className={BlogPageStyle.Content_Area}>
            <div>
              {article.meta && (
                <time dateTime={FormatDate(article.meta.metaPublishDate).toFormat('yyyy-MM-dd')} className={BlogPageStyle.Content_Date}>
                  {FormatDate(article.meta.metaPublishDate).toFormat('yyyy.MM.dd')}
                </time>
              )}
              <h1 className={BlogPageStyle.Content_Title}>{article.title}</h1>
            </div>
            <ul className={BlogPageStyle.Content_Tag_Area}>
              {article.tags?.map((tag) => (
                <li key={`tag_${tag._id}`} className={BlogPageStyle.Content_Tag}>
                  #{tag.name}
                </li>
              ))}
            </ul>
          </div>
        </article>
      </a>
    </li>
  )
}

export default BlogCard
